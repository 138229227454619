import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../assets/descaling.sass"
import Image from "../../components/image"
import Carousel from 'react-bootstrap/Carousel'

const SewerandPlumbing = () => (
  <div className="descaling-component">
    
    <SEO title="Sewer and Plumbing" />

    <div className="service-header">
      <h1>Sewer and Plumbing</h1>
    </div>

    <div className="descaling-statements descaling-statements-red">
      <p>Our professional sewer and plumbing service provides reliable, fast solutions for all your home and business needs. From emergency repairs to routine maintenance, our expert team is equipped to handle everything from clogged drains to sewer line replacements. Trust us for top-quality service, transparent pricing, and customer satisfaction.</p>
    </div>

     <div className="desktop-image-video">

      <Carousel>
        <Carousel.Item interval={2500}>
            <div className="scoping-images">
              <Image filename="descaling.JPG"/>
            </div>
        </Carousel.Item>
        <Carousel.Item interval={2500}>
            <div className="scoping-images">
              <Image filename="pipe.png"/>
            </div>
        </Carousel.Item>
        <Carousel.Item interval={1500}>
            <div className="scoping-images">
              <Image filename="power-rodding-glove.JPG"/>
            </div>
        </Carousel.Item>
      </Carousel>

    </div>
    
    <div className="descaling-statements desktop-statements">    
      <ul className="sewer-services-list">
        <li> - Drain cleaning and unclogging</li>
        <li> - Sewer line repair and replacement</li>
        <li> - Pipe inspection and leak detection</li>
        <li> - Water heater installation and repair</li>
        <li> - Fixture installation and repair</li>
        <li> - Backflow prevention</li>
        <li> - sewer repair</li>
        <li> - Sump pump installation and maintenance</li>
        <li> - Gas line services</li>
        <li> - Repiping services</li>
      </ul>
    </div>

  </div>
)

export default SewerandPlumbing
